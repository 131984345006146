@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.header {
  &__container {
    height: 70px;
  }
  &__app-bar {
    background-color: c.$white;
    box-shadow: none;
  }
  &__styled-tool-bar {
    margin-right: v.$margin-7;
    margin-left: v.$margin-7;
    padding: 0;
  }
  &__toolbar {
    height: 24px;
    width: 335px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__toolbar > a {
    text-decoration: none;
  }
  &__toolbar-guide {
    height: 24px;
    width: 110px !important;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    position:absolute;
    align-self: flex-end;
    top: 45px;
  }
  &__toolbar-guide > a {
    text-decoration: none;
  }
  &__divider {
    background-color: c.$gray-dark;
    margin-top: 6px !important;
    height: 14px;
    width: 0.2px;
    border-right-width: inherit;
  }
  @media screen and (max-width: v.$max-breakpoint-sm) {
    &__styled-tool-bar {
      flex-direction: column;
      margin-top: 10px;
    }
  }
}
