@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.button-custom {
  height: 38px;
  width: 156px;
  color: c.$gray-dark;
  border-color: c.$gray-dark;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: center;
  border-radius: 19px;
  text-transform: uppercase;
  &:hover {
    background-color: c.$gray-dark;
    color: c.$white;
  }
}