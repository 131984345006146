@import "styles/colors.scss";

.dropdown {
  min-width: 282px;
  &__label {
    color: $gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }

  &__wrapper {
    border-radius: 4px;
    background-color: $white;
    margin: 8px 0px;

    fieldset {
      border-width: 1px !important;
      border-color: $gray-dark !important;
    }

    svg {
      color: $gray-dark;
    }

    .placeholder {
      color: $gray-dark;
    }
  }
}

.MuiSelect {
  &-select {
    padding-top: 11.5px;
    padding-bottom: 11.5px;
  }
}

.MuiMenuItem {
  &-root {
    background-color:  $white !important;
    word-wrap: break-word;
    white-space: normal;
    font-weight: normal !important;
    &:hover {
      background-color:  $orange !important;
      color: $white;
    }
  }
}