@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.footer {
  &__container {
    margin-top: v.$margin-8;
  }
  &__app-bar {
    background-color: c.$blue-light;
    height: 103px;
  }
  &__styled-toolbar {
    margin-right: v.$margin-8;
    margin-left: v.$margin-8;
    padding: 0;
    height: 100%;
  }
  &__stack {
    margin: 0 !important;
    gap: 1.25rem;
    vertical-align: -4px;
    font-size: 32px;
    svg {
      :hover {
        opacity: 0.8;
      }
      fill: c.$mediaIconColor;
    }
  }
  &__social-logo {
    &--fb-logo {
      width: 32px;
    }
    &--twitter-logo {
      margin: 0 !important;
      width: 32px;
    }
    &--linkedin-logo {
      margin: 0 !important;
      width: 32px;
    }
  }
  &__copyright-text {
    color: c.$gray-dark2;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: right;
  }
  @media screen and (max-width: v.$max-breakpoint-sm) {
    &__styled-toolbar {
      flex-direction: column-reverse;
      margin: 0;
    }
    &__stack {
      font-size: 20px;
    }
    &__copyright-text {
      text-align: center;
    }
  }
}
