@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.table {
  width: "100%";
  &__title {
  }
  &__titleContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__caption {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0 !important;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  &__savedSearchText{
    font-size: 14px;
    text-align: center;
    line-height: 26px;
    color: c.$gray-dark;
    letter-spacing: 0;
    float: right;
    margin-top: -25px;
    margin-bottom: 10px;
  }
  &__head {
    .MuiTableCell-head {
      font-size: 13px;
      line-height: 24px;
      font-weight: bold;
      color: c.$blue-dark2;
      padding: 0;
    }

    &__cell {
      display: inline-flex;
      justify-content: center;
      padding: 24px 16px;

      &--active {
        border-bottom: 5px solid c.$blue-dark1;
        padding-bottom: 19px;
        svg {
          left: 2.5px;
          right: unset;
        }
      }

      &--pointer {
        cursor: pointer;
      }

      svg {
        fill: c.$blue-dark1;
      }
    }
  }

  &__body {
    .MuiTableRow-root:nth-child(even) {
      background-color: c.$gray-light-2;
    }

    .MuiTableCell-body {
      padding: 12px 16px;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      color: c.$gray-dark;
      border: 0;
      vertical-align: top;
    }
    &__pdfIcon {
      width: 39px !important;
      height: 18px;
    }
    .selectCheckbox {
      padding: 0px;
      padding-right: 15px;
    }
  }
  &__loader {
    position: absolute;
    margin-top: 75px;
    color: c.$loaderColor;
    left: 50%;
    z-index: 100;
  }
  &__loaderContainer {
    height: 150px;
  }
  &__loaderActive {
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.sorting-icon {
  margin-left: 5px;
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 30px;
  flex-direction: column;

  &__down-arrow,
  &__up-arrow {
    width: 15px;
    position: absolute;
  }

  &__down-arrow {
    left: 2.5px;
  }

  &__up-arrow {
    right: 2.5px;
  }

  &__menu {
    position: absolute;
    width: 10px;
    right: 0;
    top: 9px;

    .menu,
    .menu::after,
    .menu::before {
      box-sizing: border-box;
      position: relative;
      display: block;
      width: 10px;
      height: 2px;
      border-radius: 3px;
      background-color: c.$blue-dark1;
    }
    .menu::after,
    .menu::before {
      content: "";
      position: absolute;
      top: -4px;
    }
    .menu::after {
      top: 4px;
    }
  }
}

.action {
  &__link {
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
  }
}

.table-actions {
  &__container {
    margin-top: 20px;
  }

  &__noResultBox {
    height: 85px;
    width: 100%;
    display: flex;
    align-items: center;
    border-left: 8.63px solid c.$orange2;
  }
  &__errorIcon {
    height: 23px;
    width: 23px;
    color: c.$orange2;
    margin-right: 16px;
    margin-left: 13px;
  }
  &__noResultText {
    height: 25px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: c.$gray-dark;
  }
  &__noResultContainer {
    background-color: c.$gray-light-2;
    padding-right: 4px;
    margin-top: 20px;
  }
}
