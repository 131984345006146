@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.successModal {
  &__container {
    height: 85px;
    width: 588px;
    display: flex;
    align-items: center;
    border-left: 8.63px solid c.$orange2;
  }
  &__checkCircleIcon {
    height: 25px;
    width: 25px;
    color: c.$orange2;
    margin-right: 16px;
    margin-left: 13px;
  }
  &__closeIcon{
    margin : 10px 10px auto auto;
    cursor: pointer;
    color: c.$gray-dark;
  }
  @media screen and (max-width: v.$max-breakpoint-sm) {
    &__container {
      width: 360px;
    }
  }
}
