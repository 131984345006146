@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.CustomDialog {
  &__MuiBackdrop {
    .MuiDialog-paper {
      width: auto !important;
      height: auto;
    }
  }
  &__container {
    width: auto !important;
  }
  &__title {
    color: c.$gray-dark;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
  }
  &__titleContainer {
    height: 80px;
    width: 588px;
    background-color: c.$gray-light-2;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  &__content {
    color: c.$gray-dark;
  }
  &__cancelButton {
    background-color: c.$white;
    height: 20px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    margin-right: 24px;
    width: fit-content;
    padding: 0 !important;
    border-radius: 0;
    border-bottom: 1px solid;
    min-width: 50px;
    &:hover {
      border-bottom: 1px solid c.$white;
      color: c.$gray-dark !important;
      background-color: c.$white;
    }
  }
  &__deleteButton {
    margin: 0 !important;
    height: 38px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
  }
  &__buttonContainer {
    margin-left: auto;
    margin-right: 30px;
    margin-bottom: 28px;
  }
  @media screen and (max-width: v.$max-breakpoint-sm) {
    &__MuiBackdrop {
      .MuiDialog-paper {
        height: 350px;
      }
    }
    &__titleContainer {
      width: auto;
    }
    &__buttonContainer {
      margin-left: 0;
      margin-bottom: 28px;
    }
    &__content {
      width: 270px;
    }
  }
}
