@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.pagination {
  &__container {
    height: 39px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;  
    @media screen and (max-width: v.$max-breakpoint-sm2) {
      flex-direction: column;
    }
  }
  &__paginationItem {
    // border-bottom: 2px solid #3f97b5;
    padding: 0;
    background-color: c.$white;
    width: 15px;
    color: c.$gray-dark;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 37px;
    border-bottom-style: 1px;
    border-radius: 0;
    min-width: 0;
    &:hover {
      background-color: c.$white;
      color: c.$orange;
    }
    > svg {
      display: inline-block;
      width: 30px;
      margin: 0 -12.5px;
      color: c.$gray-dark;
      fill: none;
      &:hover {
        color: c.$white;
        circle {
          fill: c.$orange;
        }
        polyline {
          color: c.$white;
        }
      }
    }
  }
  &__arrowRight {
    height: 28px;
    width: 28px;
  }
  @media screen and (max-width: v.$max-breakpoint-sm) {
    &__paginationItem {
      margin-right: 25px;
    }
  }
  &__goto {
    display: flex;
    align-items: center;
    justify-content: center;

    &__txt {
      color: c.$gray-dark;
      margin-right: 10px;
    }

    &__select {
      min-width: auto !important;
      width: 75px !important;
    }
  }
}
