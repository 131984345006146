@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.checkbox {
  &__icon {
    border-radius: 4px;
    width: 20px;
    height: 20px;
    border: 1px solid c.$gray-dark3;
    background-color: c.$white;
    position: relative;
    &--checked{
      background-color: c.$orange;
      border: 1px solid c.$orange;
      &:before{
        display: block;
        position: absolute;
        top: -1px;
        right: -1px;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
        content: "";
      }
    }
  }
}

// input:hover ~ .checkbox__icon {
//   background-color: #ebf1f5;
// }

input:disabled ~ .checkbox__icon {
  box-shadow: none;
  background-color: c.$gray-dark3;
  opacity: .5;
}