@import "styles/colors.scss";
@import "styles/variables.scss";

.autocomplete {
  min-width: 282px;
  &__label {
    color: $gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  
  &__wrapper {
    margin: 8px 0px;
  }
  &__loader{
    .MuiCircularProgress-root{
      color: $loaderColor;
    }
  }
}

.MuiAutocomplete {
  &-inputRoot {
    border: 1px solid $gray-dark;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    background-color: $white;
  }
  &-input {
    @include placeholder($gray-dark, "", 1);
  }
  &-option.Mui-focused {
    background-color:  $orange !important;
  }
  &-option[aria-selected="true"] {
    background-color:  rgba(0, 0, 0, 0.04) !important;
  }
}