@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

*:focus {
  outline: none;
}

.advanceSearchPage {
  &__wrapper {
    position: relative;
    height: 60px;
    @media screen and (max-width: v.$max-breakpoint-lg) {
      height: auto;
      position: unset;
    }
  }
  &__container {
    position: absolute;
    top: -427px;
    @media screen and (max-width: v.$max-breakpoint-lg) {
      top: 0;
      position: unset;
    }
  }
  &__result {
    margin-top: 50px;
  }
  &__keywordTextBox {
    height: 46px;
    > input::placeholder {
      height: 46px;
      color: c.$gray-dark;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
  &__keywordTextBoxInput {
    height: 46px;
    min-width: 400px;
    border: 1px solid c.$gray-dark;
    border-radius: 4px;
    background-color: c.$white;
    margin-top: 8px;
    padding: 10px;
    @media screen and (max-width: v.$max-breakpoint-sm2) {
      min-width: 288px;
    }
  }
  &__keywordCancelButton {
    margin-left: 30px;
    height: 18px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.25;
    text-align: center;
    border-bottom: 1px solid;
    width: fit-content;
    border-radius: 0;
    padding: 0;
    min-width: 50px;
    &:hover {
      border-bottom: none;
      color: c.$gray-dark !important;
      background-color: inherit;
    }
  }
  &__keywordTextBoxLabel {
    height: 24px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__datepickerLabel {
    height: 24px;
    width: 33px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    &--disabled {
      color: c.$gray-light-3;
    }
  }
  &__datepickerContainer {
    height: 46px;
    width: 283.03px;
    border: 1px solid c.$gray-dark3;
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 7px;
    input {
      height: 15px;
    }
    fieldset {
      height: 49px;
      border-color: transparent !important;
    }
    svg {
      height: 14px;
      width: 14px;
      margin-right: 10px;
      &:hover {
        background-color: transparent;
      }
    }
    box-shadow: none;
    border-color: c.$gray-dark !important;
    &__datepickerContainerDisabled {
      border-color: c.$gray-light-3 !important;
    }
  }

  &__searchButton {
    margin-left: auto;
    height: 38px;
    color: c.$gray-dark;
    border-color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    border-radius: 19px;
    margin-top: 50px;
    &:hover {
      background-color: c.$gray-dark;
      color: c.$white;
    }
  }
  &__runOnceText {
    font-size: 14px;
    text-align: center;
    margin-top: 6px;
    line-height: 26px;
    color: c.$gray-dark;
    letter-spacing: 0;
  }
  &__yourSearchCriteriaBox {
    display: flex;
    flex-direction: column;
    min-height: 150px;
    border: 1px solid c.$brown;
    background-color: c.$blue-dark;
    border-bottom: 6px solid c.$green-dark;
    align-items: center;
    margin-bottom: 50px;
    padding-bottom: 20px !important;
    padding-right: 10px !important;
  }
  &__yourSearchCriteriaText {
    margin-top: 30px;
    margin-left: 20px;
    height: 30px;
    color: c.$white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 30px;
    margin-right: auto;
    margin-bottom: 15px;
  }
  &__yourSearchCriteriaButton {
    margin-left: auto;
    height: 38px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    border-radius: 19px;
    background-color: c.$white;
    &:hover {
      background-color: c.$gray-dark;
      color: c.$white;
    }
  }
  &__searchOptionsBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-left: 20px;
    border-radius: 19px;
  }
  &__chipsArray {
    background-color: c.$green-dark;
    height: 30px;
    color: c.$white;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
  }
  &__tableWrapper {
    margin-top: -132px;
    @media screen and (max-width: v.$max-breakpoint-lg) {
      margin-top: 0;
    }
  }
  @media screen and (max-width: v.$max-breakpoint-sm) {
    &__searchButton {
      margin-left: 0;
    }
    &__runOnceText {
      margin-bottom: 30px;
    }
    &__searchOptionsBox {
      flex-direction: column;
    }
    &__yourSearchCriteriaButton {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: v.$max-breakpoint-sm) {
    .MuiChip-label {
      width: 200px;
    }
  }
}

.searchBox {
  &__container {
    margin: auto;
    min-height: 100px;
    height: auto;
    margin-top: 50px;
  }
  &__inputbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &:hover {
      border-color: transparent;
      outline: none;
    }
  }
}

//Appending default MUI classes

.MuiPickersCalendarHeader-root {
  background-color: c.$gray-light-2;
  max-height: 40px;
  padding: 0;
  height: 40px;
  margin-top: 0px;
}

.MuiPickersCalendarHeader-labelContainer {
  margin: auto;
  padding-left: 40px;
}

.MuiPickersArrowSwitcher-spacer {
  width: 0;
}

.MuiIconButton-edgeEnd {
  padding: 0;
}

.MuiPickersDay-today {
  border: 1px solid c.$orange !important;
}

.MuiCalendarPicker-root {
  width: 283px;
  @media screen and (max-width: v.$max-breakpoint-lg) {
    width: 320px;
  }
}

.MuiCalendarOrClockPicker-root {
  width: 283px;
  > div {
    width: 283px;
  }
  @media screen and (max-width: v.$max-breakpoint-lg) {
    width: 320px;
    > div {
      width: 320px;
    }
  }
}

.MuiPickersDay-root.Mui-selected {
  background-color: c.$orange !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}
