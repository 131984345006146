@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.modal {
  &__modalheadingContainer {
    height: 103px;
    width: 588px;
    background-color: c.$gray-light-2;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  &__heading {
    height: 43px;
    width: 125px;
    color: #4a4f55;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__searchlabel {
    height: 24px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__body {
    margin-left: 30px;
    margin-right: 30px;
  }
  &__searchInput {
    box-sizing: border-box;
    height: 46px;
    width: 528px;
    border: 1px solid c.$gray-dark3;
    border-radius: 4px;
    background-color: c.$white;
    padding: 15px;
  }
  &__inputBox {
    margin-bottom: 30px;
    > input ::placeholder {
      height: 30px;
      width: 130px;
      color: c.$gray-dark;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
  &__emailLabel {
    height: 24px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__radioInputLabel {
    height: 30px;
    color: c.$gray-dark;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    margin-right: 11px;
  }
  &__radioInput {
    margin-right: 10px;
    accent-color: c.$orange;
  }
  &__radio {
    margin-bottom: 30px;
  }
  &__cancelButton {
    background-color: c.$white;
    height: 18px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    margin-right: 24px;
    border-bottom: 1px solid;
    width: fit-content;
    border-radius: 0;
    padding: 0;
    min-width: 50px;
    &:hover {
      border-bottom: 1px solid c.$white;
      color: c.$gray-dark !important;
      background-color: c.$white;
    }
  }
  &__saveSearchButton {
    height: 38px;
    width: 154px;
    color: c.$gray-dark;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
  }
  &__buttonContainer {
    margin-left: auto;
    margin-right: 30px;
    margin-bottom: 28px;
  }
  &__emailErrorMessage {
    color: c.$red;
    font-size: 13px;
  }
  @media screen and (max-width: v.$max-breakpoint-sm) {
    &__searchInput {
      width: 350px;
    }
    &__buttonContainer {
      margin: 0;
    }
  }
}
