@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.priceChart{
  &__loader{
    position: absolute;
    margin-top: 75px;
    color: c.$gray-dark2;
    left: 50%;
    z-index: 10;
  }
  &__loaderContainer{
    height: 275px;
  }
}
.event-icon {
  width: 17.5px;
  height: 26px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='17px' height='23px' viewBox='0 0 17 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EGroup 6 Copy 5%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03' transform='translate(-806.000000  -893.000000)'%3E%3Cg id='Group-4' transform='translate(806.000000  893.000000)'%3E%3Cpath d='M8.5 23 C14.1666667 18.9205898 17 15.353262 17 12.2980167 C17 7.7151486 13.1944204 4 8.5 4 C3.80557963 4 0 7.7151486 0 12.2980167 C0 15.353262 2.83333333 18.9205898 8.5 23 Z' id='Oval' fill='%23FF5D00'%3E%3C/path%3E%3Ctext id='R' font-family='Cairo-Bold  Cairo' font-size='12' font-weight='bold' letter-spacing='0.000150000007' fill='%23FFFFFF'%3E%3Ctspan x='5' y='16'%3ER%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
 }
 .eventTooltip {
  bottom: 130px;
  right: 80px;
  position:relative;
  width: 179px;
  height:121px;
  background-color: c.$white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.26);
  z-index: 1;
  > .header {
    background-color: c.$gray-light-2;
    height: 44px;
    width: 179px;
    color: c.$gray-dark;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 23px;
    padding: 11px 30px;
  }
}
.eventContent {
  padding-bottom: 16px;
}
td {
  height: 23px; 
  color: c.$gray-dark;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
  padding-top: 9px;
  &.eventlabel {
    width: 25px;
    padding-right: 44px;
    padding-left: 20px;
  }
  &.eventval {
    width: 78px;
    font-weight: bold;
  }
}
#modchartsPriceChart {
  width: 100%;
  height: 275px;
  margin-top: 50px;
}
.modcharts-root-loading {
  pointer-events: none;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
// .modcharts-root-loading::before {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   //animation: pulse 2s ease infinite;
//   //content: "Loading...";
//   align-items: center;
//   justify-content: center;
//   font-size: 1rem;
//   opacity: 1;
// }
.modcharts-root-loading .modcharts-legend,
.modcharts-root-loading .modcharts-panel {
  opacity: 0.5;
}