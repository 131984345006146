@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.searchPage {
  &__wrapper {
    position: relative;
    height: 60px;
    width: 100%;
    @media screen and (max-width: v.$max-breakpoint-lg) {
      height: auto;
      position: unset;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -216px;
    width: 100%;
    @media screen and (max-width: v.$max-breakpoint-lg) {
      top: 0;
      position: unset;
    }
  }
  &__dropdown {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
  &__searchBox {
    height: 46px;
    width: 59%;
  }
  &__researchType {
    height: 46px;
    width: 39.7%;
  }
  &__searchButton {
    margin-left: auto;
    margin-top: 50px;
  }
  &__oneClickSearchBox {
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    min-height: 132px;
    border: 1px solid c.$brown;
    background-color: c.$blue-dark;
    border-bottom: 6px solid c.$green-dark;
    margin-bottom: 50px;
  }
  &__oneClickSearchText {
    height: 30px;
    width: 174px;
    color: c.$white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 30px;
    position: relative;
    top: 30px;
    left: 20px;
  }
  &__oneClickStack {
    height: 30px;
    color: c.$white;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    top: 32px;
    left: 20px;
    > a {
      text-decoration: none;
      border-bottom: 1px solid;
      width: fit-content;
      color: c.$white;
      &:hover {
        color: c.$white;
        border-bottom: none;
      }
    }
  }
  &__onClickColor {
    //color:c.$gray-dark !important;
    border-bottom: none !important;
  }
  &__oneClickDivider {
    background-color: c.$white;
    margin-top: 8px !important;
    height: 16px;
    width: 0.2px;
    border-right-width: inherit;
  }

  &__result {
    margin-top: 50px;
  }
  &__researchRatingContainer {
    position: relative;
    display: flex;
    margin-top: 31px;
    height: 74px;
    background-color: c.$gray-light-2;
    border-left: 6px solid c.$blue-dark;
    margin-bottom: 27px;
  }
  &__researchRatingText {
    display: inline-flex;
    margin-left: 36px;
    margin-top: 25px;
    margin-bottom: 25px;
    height: 24px;
    width: 931px;
    color: c.$gray-dark;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 24px;
    > div {
      padding-left: 7px;
    }
  }
  &__researchRatingHeader {
    height: 43px;
    color: c.$gray-dark;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    margin-top: 50px;
    margin-bottom: 31px;
    > span {
      position: absolute;
      margin-top: 4px;
      margin-left: 10px;
      height: 37px;
      width: 25px;
    }
    &__pdfLink {
      cursor: pointer;
    }
  }
  &__researchRatingData {
    font-weight: bold;
  }
  &__researchRatingStack {
    right: 0px;
    position: absolute;
    height: 23px;
    margin-bottom: 25px;
    margin-top: 25px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    margin-right: 23px;
    > a {
      text-decoration: none;
      color: c.$orange;
    }
    > svg {
      height: 23px;
      width: 26px;
    }
    &__disableChart {
      display: none;
    }
  }

  @media screen and (max-width: v.$max-breakpoint-sm) {
    &__dropdown {
      flex-direction: column;
    }
    &__researchType {
      margin-top: 40px;
    }
    &__searchBox {
      margin-top: 10px;
    }
    &__oneClickSearchBox {
      margin-top: 17px;
      min-height: 325px;
    }
    &__oneClickStack {
      flex-direction: column;
      height: auto;
      > a {
        margin-left: 0 !important;
      }
    }
    &__oneClickDivider {
      width: 0;
    }
    &__researchRatingText {
      flex-direction: column;
    }
    &__researchRatingContainer {
      margin-top: 55px !important;
    }
  }
  @media screen and (max-width: v.$max-breakpoint-lg) {
    &__oneClickSearchBox {
      margin-top: 17px;
    }
  }
}

.MuiBox-root {
  padding: 0;
}
