@use "styles/variables.scss" as v;
@use "styles/colors.scss" as c;

.tabs {
  &__container {
    padding: 0;
    max-width: v.$content-max-width;
    @media screen and (max-width: v.$max-breakpoint-lg) {
      padding: 0 20px;
    }
  }
  &__box {
    background-color: c.$gray-light-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: v.$max-breakpoint-lg) {
      height: auto;
    }
  }

  &__heading {
    height: 62px;
    width: 321px;
    color: c.$gray-dark;
    font-family: v.$DM-font;
    font-size: 45px;
    letter-spacing: 0;
    line-height: 62px;
    margin-top: 88px;
    margin-bottom: 10px;
  }
  &__search-tabs {
    padding: 0;
    margin-right: auto;
  }
  &__tab {
    height: 24px;
    color: c.$gray-dark;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 0;
    min-width: 0;
    margin-right: 22px;
    &:hover {
      color: c.$orange;
    }
  }
}

.Mui-selected {
  font-weight: bold;
}
