// Container
$content-max-width: 1200px;
$font-family: "Cairo", sans-serif;

// MARGINS
$margin-1: 5px;
$margin-2: 8px;
$margin-3: 10px;
$margin-4: 15px;
$margin-5: 20px;
$margin-6: 25px;
$margin-7: 30px;
$margin-8: 50px;

// BORDERS
$border-radius-1: 5px;

// FONTS
$font-1: 13px;
$DM-font: "DM Serif Display";
//SCREENSIZE
$max-breakpoint-xs: 0px;
$max-breakpoint-sm: 600px;
$max-breakpoint-sm2: 720px;
$max-breakpoint-md: 900px;
$max-breakpoint-lg: 1200px;
$max-breakpoint-xl: 1536px;


// Create placeholder mixin
@mixin placeholder($color, $size:"", $opacity: "") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $opacity != "" {
      opacity: $opacity;
    }    
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
    @if $opacity != "" {
      opacity: $opacity;
    }  
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
    @if $opacity != "" {
      opacity: $opacity;
    }  
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
    @if $opacity != "" {
      opacity: $opacity;
    }  
  }
}

:export {
  fontFamily: $font-family;
}
