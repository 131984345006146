$green-dark: #1e5162;
$blue-dark: #3f97b5;
$blue-dark1: #1b4356;
$blue-dark2: #1a4456;
$blue-light: #7fb5c8;
$gray-dark: #4a4f55;
$gray-dark2: #182f37;
$gray-dark3: #929292;
$orange: #ff5d00;
$orange2: #ff6a14;
$white: #ffffff;
$gray-light-1: #e6e6e6;
$gray-light-2: #f4f4f4;
$gray-light-3: #6666;
$brown: #979797;
$red: #ff0000;

$mediaIconColor : #182f37;
$loaderColor : #182f37;

:export {
  tableHeaderColor: $blue-light;
  textColor: $gray-dark;
  anchorHoverColor: $orange;
  whiteColor: $white;
  blueDark: $blue-dark;
}
